<template>
  <a-layout id="components-layout-demo-fixed">
    <a-layout-content>
      <div :style="{ width: '100%', background: headerBgColor, padding: '23px 0' }"
        style="position: fixed; z-index: 99; width: 100%">
        <div class="pc-box" v-if="fullWidth > 992">
          <a-row type="flex" align="middle">
            <a-col :span="4">
              <div class="pc-logo">
                <img src="/images/logo.png" width="160px" height="76px" />
              </div>
            </a-col>
            <a-col :span="17">
              <div class="pc-menu" style="display: flex; flex-direction: column; justify-content: center">
                <HeaderMenu></HeaderMenu>
              </div>
            </a-col>
            <a-col :span="3">
              <div class="pc-login" v-if="$route.name != 'Login' && !userInfo.id">
                <router-link class="link-font" to="/login"> 登录</router-link>
              </div>
              <div v-if="$route.name != 'Login' && userInfo.id" style="display: flex">
                <a-dropdown>
                  <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="1">
                      <router-link :to="{ name: 'MySpecialTopic', query: { isMySpecial: true } }"
                        style="text-align: center">
                        我的专题
                      </router-link>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <router-link to="/myService" style="text-align: center">
                        我的服务
                      </router-link>
                    </a-menu-item>
                    <!-- 我的强制报告 -->
                    <a-menu-item key="3">
                      <router-link to="/myreport" style="text-align: center">我的报告</router-link>
                    </a-menu-item>
                    <a-menu-item key="4">
                      <router-link to="/myCloudCourse" style="text-align: center">我的云课堂</router-link>
                    </a-menu-item>
                    <!-- 我的预约 -->
                    <a-menu-item key="5">
                      <router-link to="/myorder" style="text-align: center">我的预约</router-link>
                    </a-menu-item>
                    <a-menu-item key="6">
                      <router-link to="/myRecords" style="text-align: center">我的兑换记录</router-link>
                    </a-menu-item>
                    <a-menu-item key="7">
                      <router-link to="/feedback" style="text-align: center">意见反馈</router-link>
                    </a-menu-item>
                    <a-menu-item key="8">
                      <div @click="centerDialogVisible = true" style="text-align: center">在线客服</div>
                    </a-menu-item>
                    <a-menu-item key="9">
                      <div style="text-align: center" @click="dialogVisible = true">
                        退出登录
                      </div>
                      <!-- <router-link to="/" style="text-align: center;">退出登录</router-link> -->
                    </a-menu-item>
                  </a-menu>
                  <a-button style="margin-left: 8px; padding: 0 6px" size="large">
                    <div style="display: flex; align-items: center">
                      <a-avatar shape="square" icon="user" :src="userInfo.avatar" />
                      <div style="margin-left: 10px;
                                      font-size: 14px;
                                      color: #424242;
                                      font-weight: 400;
                                    ">
                        {{
                          userInfo.name ? userInfo.name : userInfo.realname
                        }}
                      </div>
                      <a-icon type="down" style="font-size: 14px; color: #c3c3c3; margin-left: 10px" />
                    </div>
                  </a-button>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="mobile-box" v-else>
          <div class="menu-switch">1-1</div>
        </div>
      </div>
      <div>
        <router-view />
      </div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center', position: 'relative', zIndex: 1 }">
      <a-row type="flex" justify="center">
        <a-col :span="20" :lg="16">
          <Footer></Footer>
        </a-col>
      </a-row>
      <div>
        黔ICP备2022007381号-1
        <!-- 指导单位:贵州省检察院、贵州省民政厅、贵州省教育厅、贵州省卫健委、贵州省公安厅、贵州省司法厅、贵州省共青团、贵州省妇联、贵州省监察厅； -->
      </div>
      <!-- <div>
        建设单位：贵州省毕节检察院；技术开发：贵州中汇科技发展有限公司；邮箱：24614145@qq.com
        2021 shouwei.skillhub.top 版权所有 黔ICP备16002619号-1
      </div> -->
    </a-layout-footer>

    <el-dialog title="提示" :visible.sync="dialogVisible" :width="fullWidth > 1000 ? '30%' : '100%'"
      @closed="dialogVisible = false" @opened="dialogVisible = true">
      <span>确定退出当前账号?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="loginOut()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="在线客服" :visible.sync="centerDialogVisible" :width="fullWidth > 1000 ? '30%' : '100%'" center>
      <div class="online_service">客服电话：18185113258</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </a-layout>
</template>

<script>
import HeaderMenu from "../components/default-header/default-header.vue";
import Footer from "../components/default-footer/default-footer.vue";
import Parames from "../config/parames.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    HeaderMenu,
    Footer,
  },
  data() {
    return {
      dialogVisible: false,
      headerBgColor: "rgba(55,182,223,0)",
      centerDialogVisible: false,
    };
  },
  computed: {
    ...mapState(["fullWidth", "userInfo"]),
  },
  created() {
    window.addEventListener("resize", () => {
      // if (!this.timer) { // 使用节流机制，降低函数被触发的频率
      // 	this.timer = true;
      let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
      // 	setTimeout(function() {
      that.handleResize();
      // 		that.timer = false;
      // 	}, 400)
      // }
    });
    window.onscroll = () => {
      let opacity = window.pageYOffset / 250;
      this.headerBgColor = `rgba(55,182,223,${opacity})`;
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations(["handleResize"]),
    handleMenuClick(e) {
      console.log("click", e);
    },
    loginOut() {
      this.dialogVisible = false;
      this.$HTTP.loginOutStore();
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style>
.pc-box {
  height: 76px;
  line-height: 76px;
  box-sizing: border-box;
  /* padding: 23px 0; */
  /* background-color: #2C3E50; */
}

.ant-menu-horizontal {
  /* line-height: inherit; */
  border-bottom: 0;
}

.pc-menu {
  height: 76px;
  /* line-height: 0; */
}

.pc-login {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.ant-layout,
.ant-layout-footer {
  background-color: transparent;
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0;
  margin: 0 20px;
}

.banner-img {
  position: relative;
  /* background-image: url('/images/home-banner.png');
		background-repeat: no-repeat;
		width: 100%;
		height: 632px;
		background-size: cover; */
}

.online_service {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}
</style>
