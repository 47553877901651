<template>
  <div class="header" ref="headerRef" style="position: relative;">
    <a-menu v-model="current" mode="horizontal" style="position: relative;">
      <a-menu-item key="Home">
        <router-link class="link-font" to="/">
          <div class="menu-text">首页</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="Report">
        <router-link class="link-font" to="/report">
          <div class="menu-text">强制报告</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="Classroom">
        <router-link class="link-font" to="/classroom">
          <div class="menu-text">云课堂</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="SpecialClass">
        <router-link class="link-font" to="/specialclass">
          <div class="menu-text">专题课程</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="Serve">
        <router-link class="link-font" to="/serve">
          <div class="menu-text">守未服务</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="CloudBase">
        <router-link class="link-font" to="/cloud-base">
          <div class="menu-text">云上基地</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="LegalCompetition">
        <router-link class="link-font" to="/legalcompetition">
          <div class="menu-text">法治比赛</div>
        </router-link>
      </a-menu-item>
      <a-menu-item key="platform">
        <div class="menu-text" @click="gotoPlatform()">管理后台</div>
      </a-menu-item>
      <a-menu-item key="AppDownLoad">
        <div class="menu-text" @click="gotoAppDownload()">APP下载</div>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DefaultHeader",
  props: {},
  data() {
    return {
      current: ["Home"],
    };
  },
  computed: {
    ...mapState(["pageName"]),
  },
  watch: {
    $route(newVal, oldVal) {
      console.log("watch------$route", newVal, oldVal);
      this.current = [this.$route.name];
    },
  },
  created() {
    setTimeout(() => {
      this.current = [this.$route.name];
    }, 500);
  },
  mounted() {
     //获取屏幕尺寸
     this.screenWidth = document.body.clientWidth
     this.$refs.headerRef.style.width=this.screenWidth-610+'px'
        window.onresize = () => {
            console.log('高度');
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth
                this.$refs.headerRef.style.width=this.screenWidth-610+'px'
            })()
        }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    gotoAppDownload() {
      window.open("https://download.skillhub.top");
    },
    gotoPlatform() {
      if (this.$store.getters.token && this.$store.getters.token.length != 0) {
        let url = "https://swlmadminprod.skillhub.top?token=" + this.$store.getters.token;
        window.open(url);
      } else {
        let url = "https://swlmadminprod.skillhub.top";
        window.open(url);
      }
    },
  }
};
</script>

<style>
.menu-text {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.ant-menu {
  background-color: transparent;
}

.ant-menu-horizontal>.ant-menu-item:hover {
  /* color: #FFFFFF;
  border-bottom: 3px #FFFFFF; */
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  color: #ffffff;
  font-weight: bold;
  border-bottom: 3px solid #ffffff;
}

a:active,
a:hover {
  font-weight: bold;
  color: inherit;
}

.ant-menu-submenu > .ant-menu{
  background-color: rgb(55, 182, 223)!important;
}
</style>
