<template>
  <div id="app">
    <component :is="layout">
      <keep-alive>
        <router-view />
      </keep-alive>
    </component>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      console.log(this.$route.meta.layout);
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
  },
  mounted() {
    let userInfo = localStorage.getItem("SW_USER_INFO");
    if (userInfo) {
      console.log(userInfo);
      console.log("获取到用户数据");
      let userInfoObj = JSON.parse(userInfo);

      console.log(userInfo);
      this.setUserInfo(userInfoObj);
    }
  },
};
</script>

<style>
body {
  background-color: #f8fafd !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.default-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
}

.title-font {
  font-size: 26px;
  color: #373737;
  font-weight: bold;
}

/* .content-box {
		box-sizing: border-box;
		width: 65%;
		margin: auto;
	} */

/* start--文本行数限制--start */
.u-line-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.u-line-2 {
  -webkit-line-clamp: 2;
}

.u-line-3 {
  -webkit-line-clamp: 3;
}

.u-line-4 {
  -webkit-line-clamp: 4;
}

.u-line-5 {
  -webkit-line-clamp: 5;
}

.u-line-2,
.u-line-3,
.u-line-4,
.u-line-5 {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.link-font {
  color: inherit;
}
</style>
