<script>
// const serverSrc = "https://swlmdev.skillhub.top/common-api"; // 【用于测试环境】设置接口请求的公共域名
// const prodStaticSourceHost = "https://swlmapph5prod.skillhub.top/sw-static";
// const secret = "e4cd404b0f9a9c026fd1e5c06e190e2c"; //密钥
// const token = "123456"; //模拟token值
// const AppKey = "TEST-74545BB-EMERGENCY-APP"; // 测试服APPKEY

const serverSrc = "https://swlmprod.skillhub.top/common-api"; // 【用于测试环境】设置接口请求的公共域名
const prodStaticSourceHost = "https://swlmapph5prod.skillhub.top/sw-static";
const secret = "e4cd404b0f9a9c026fd1e5c06e190e2c"; //密钥
const token = "123456"; //模拟token值
const AppKey = "PROD-74545BB-EMERGENCY-APP"; // 正式服APPKEY

export default {
  secret, //用户地址
  token, //用户token身份
  serverSrc, //服务器地址
  AppKey,
  prodStaticSourceHost,
};
</script>
