import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

let routes = [{
	path: '/',
	name: 'Home',
	layout: 'default',
	component: Home
},
{
	path: '/about',
	name: 'About',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
	path: '/report',
	name: 'Report',
	layout: 'default',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/Report.vue')
},
// 我的强制报告
{
	path: '/myreport',
	name: 'MyReport',
	layout: 'default',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/MyReport')
},
// 我的强制报表详情
{
	path: '/myreportdetails',
	name: 'MyReportDetails',
	layout: 'default',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/MyReportDetails.vue')
},
{
	path: '/live',
	name: 'ClassroomLive',
	component: () => import('../views/course/Live.vue')
},
{
	path: '/classroom',
	name: 'Classroom',
	layout: 'default',
	component: () => import( /* webpackChunkName: "about" */ '../views/Classroom.vue'),
	redirect: '/classroom/card',
	children: [
		{
			path: 'card',
			name: 'ClassroomCard',
			component: () => import('../components/classroom-list/classroom-card.vue')
		},
		{
			path: 'details',
			name: 'ClassroomDetails',
			component: () => import('../views/course/details.vue')
		}, {
			path: 'create',
			name: 'ClassroomCreate',
			component: () => import('../views/course/create.vue')
		},
	]
},
// 专题课堂
{
	path: '/specialclass',
	name: 'SpecialClass',
	layout: 'default',
	component: () => import( /* webpackChunkName: "about" */ '../views/SpecialClass.vue'),
	redirect: '/specialclass/specialcard',
	children: [
		{
			path: 'specialcard',
			name: 'SpecialCard',
			component: () => import('../components/special-class-list/special-class-card.vue')
		},
		{
			path: 'specialdetails',
			name: 'SpecialDetails',
			component: () => import('../views/course/SpecialClassDetails.vue')
		},
		{
			path: 'articledetails',
			name: 'ArticleDetails',
			component: () => import('../components/special-class-list/special-article-details.vue')
		},
		{
			path: 'testregister',
			name: 'TestRegister',
			component: () => import('../components/special-class-list/test-register.vue')
		},
		{
			path: '/specialclass/testpaper',
			name: 'TestPaper',
			component: () => import('../components/special-class-list/test-paper.vue')
		},
	]
},
//我的题课堂
{
	path: '/mySpecialclass',
	name: 'MySpecialTopic',
	layout: 'default',
	component: () => import( /* webpackChunkName: "about" */ '../views/MySpecialTopic.vue'),
	redirect: '/mySpecialclass/specialcard',
	children: [
		{
			path: 'specialcard',
			name: 'SpecialCard',
			component: () => import('../components/special-class-list/special-class-card.vue')
		},
	]
}, {
	path: '/myCloudCourse',
	name: 'MyCloudCourse',
	layout: 'default',
	component: () => import( /* webpackChunkName: "about" */ '../views/MyCloudCourse.vue'),
	redirect: '/myCloudCourse/coursecard',
	children: [
		{
			path: 'coursecard',
			name: 'coursecard',
			component: () => import('../components/classroom-list/MyClassCourseHeader.vue')
		},
	]
},
{
	path: '/myService',
	name: 'MyService',
	layout: 'default',
	component: () => import( /* webpackChunkName: "about" */ '../views/MyService.vue'),
	redirect: '/myService/servicecard',
	children: [
		{
			path: 'servicecard',
			name: 'Servicecard',
			component: () => import('../components/sw-service/MyServiceHeader.vue')
		},
	]
},
{
	path: '/serve',
	name: 'Serve',
	layout: 'default',
	component: () => import('../views/Serve.vue'),
	redirect: '/serve/list',
	children: [
		{
			path: 'list',
			name: 'ServeList',
			component: () => import('../components/sw-service/section-list.vue')
		},
		{
			path: 'inspect',
			name: 'inspect',
			component: () => import('../views/service/inspect.vue')
		},
		{
			path: 'consult',
			name: 'inspect',
			component: () => import('../components/sw-service/consult-view.vue')
		}
	]
},
{
	path: '/login',
	name: 'Login',
	layout: 'default',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/Login.vue')
},
{
	path: '/cloud-base',
	name: 'CloudBase',
	layout: 'default',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../views/CloudBase.vue')
},
// 我的预约
{
	path: '/myorder',
	name: 'MyOrder',
	layout: 'default',
	redirect: '/myorder/myorderlist',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../components/my-order/Myorder.vue'),
	children: [
		{
			path: 'myorderlist',
			name: 'MyOrderList',
			component: () => import('../components/my-order/my-order-list.vue')
		},
	]
},
// 我的兑换记录
{
	path: '/myRecords',
	name: 'myRecords',
	layout: 'default',
	redirect: '/myRecords/myRecordsList',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../components/exchange-records/myRecords.vue'),
	children: [
		{
			path: 'myRecordsList',
			name: 'MyRecordsList',
			component: () => import('../components/exchange-records/myRecordsList.vue')
		},
	]
},
// 意见反馈
{
	path: '/feedback',
	name: 'feedback',
	layout: 'default',
	redirect: '/feedback/feedbackContent',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import( /* webpackChunkName: "about" */ '../components/feedback/feedback.vue'),
	children: [
		{
			path: 'feedbackContent',
			name: 'feedbackContent',
			component: () => import('../components/feedback/feedbackContent.vue')
		},
	]
},
// 法治比赛
{
	path: '/legalcompetition',
	name: 'LegalCompetition',
	layout: 'default',
	// redirect: '/legalcompetition/legalcard',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	redirect: '/legalcompetition/legalcard',
	component: () => import( /* webpackChunkName: "about" */ '../views/LegalCompetition.vue'),
	children: [
		{
			path: 'legalcard',
			name: 'LegalCard',
			component: () => import('../components/legal-competition/legal-competition-card')
		},
		{
			path: 'challengeanswer',
			name: 'ChallengeAnswer',
			component: () => import('../components/legal-competition/legal-competition-list')
		},
		{
			path: 'learncompetition',
			name: 'LearnCompetition',
			component: () => import('../components/legal-competition/learn-competition-list')
		},
		{
			path: 'learndetails',
			name: 'LearnDetails',
			component: () => import('../components/legal-competition/learn-details')
		},
		{
			path: 'answerrecord',
			name: 'AnswerRecord',
			component: () => import('../components/legal-competition/answer-record')
		},
		{
			path: 'viewsgrades',
			name: 'ViewsGrades',
			component: () => import('../components/legal-competition/views-grades')
		},
		{
			path: 'awardlist',
			name: 'AwardList',
			component: () => import('../components/legal-competition/award-list')
		},
	]
},
]

function addLayoutToRoute(route, parentLayout = "none") {
	route.meta = route.meta || {};
	route.meta.layout = route.layout || parentLayout;

	if (route.children) {
		route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
	}
	return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
