<template>
  <div style="padding-bottom: 40px;">
    <a-row type="flex" align="middle" justify="center" style="margin:0px 80px">
      <a-col :span="16" :lg="5">
        <img src="/images/footer-img.png" width="100%" />
      </a-col>
      <a-col :span="24" :lg="19" :style="{ marginLeft: fullWidth > 992 ? '-46px' : '0' }">
        <div
          style="background-color: #FFFFFF;box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);border-radius: 8px;box-sizing: border-box;padding: 15px 20px;">
          <a-row type="flex" align="middle">
            <a-col :span="24" :lg="20">
              <div style="display: flex;align-items: center;">
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIiB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHN0eWxlPSJmaWxsOiByZ2JhKDIxNSwgMjE1LCAyMTUsIDEpO2JvcmRlci1jb2xvcjogcmdiYSgwLDAsMCwwKTtib3JkZXItd2lkdGg6IGJweDtib3JkZXItc3R5bGU6IHVuZGVmaW5lZCIgZmlsdGVyPSJub25lIj48cGF0aCBkPSJNMTI4IDI0NHYtNDBjMC02LjYyNyA1LjM3My0xMiAxMi0xMmg0MGM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjQwYzAgNi42MjctNS4zNzMgMTItMTIgMTJoLTQwYy02LjYyNyAwLTEyLTUuMzczLTEyLTEyem0xNDAgMTJoNDBjNi42MjcgMCAxMi01LjM3MyAxMi0xMnYtNDBjMC02LjYyNy01LjM3My0xMi0xMi0xMmgtNDBjLTYuNjI3IDAtMTIgNS4zNzMtMTIgMTJ2NDBjMCA2LjYyNyA1LjM3MyAxMiAxMiAxMnptLTc2IDg0di00MGMwLTYuNjI3LTUuMzczLTEyLTEyLTEyaC00MGMtNi42MjcgMC0xMiA1LjM3My0xMiAxMnY0MGMwIDYuNjI3IDUuMzczIDEyIDEyIDEyaDQwYzYuNjI3IDAgMTItNS4zNzMgMTItMTJ6bTc2IDEyaDQwYzYuNjI3IDAgMTItNS4zNzMgMTItMTJ2LTQwYzAtNi42MjctNS4zNzMtMTItMTItMTJoLTQwYy02LjYyNyAwLTEyIDUuMzczLTEyIDEydjQwYzAgNi42MjcgNS4zNzMgMTIgMTIgMTJ6bTE4MCAxMjR2MzZIMHYtMzZjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxOS41Vjg1LjAzNUMzMS41IDczLjQxOCA0Mi4yNDUgNjQgNTUuNSA2NEgxNDRWMjRjMC0xMy4yNTUgMTAuNzQ1LTI0IDI0LTI0aDExMmMxMy4yNTUgMCAyNCAxMC43NDUgMjQgMjR2NDBoODguNWMxMy4yNTUgMCAyNCA5LjQxOCAyNCAyMS4wMzVWNDY0SDQzNmM2LjYyNyAwIDEyIDUuMzczIDEyIDEyek03OS41IDQ2M0gxOTJ2LTY3YzAtNi42MjcgNS4zNzMtMTIgMTItMTJoNDBjNi42MjcgMCAxMiA1LjM3MyAxMiAxMnY2N2gxMTIuNVYxMTJIMzA0djI0YzAgMTMuMjU1LTEwLjc0NSAyNC0yNCAyNEgxNjhjLTEzLjI1NSAwLTI0LTEwLjc0NS0yNC0yNHYtMjRINzkuNXYzNTF6TTI2NiA2NGgtMjZWMzhhNiA2IDAgMCAwLTYtNmgtMjBhNiA2IDAgMCAwLTYgNnYyNmgtMjZhNiA2IDAgMCAwLTYgNnYyMGE2IDYgMCAwIDAgNiA2aDI2djI2YTYgNiAwIDAgMCA2IDZoMjBhNiA2IDAgMCAwIDYtNlY5NmgyNmE2IDYgMCAwIDAgNi02VjcwYTYgNiAwIDAgMC02LTZ6Ij48L3BhdGg+PC9zdmc+"
                  width="19px">
                <div> &nbsp;建设单位：毕节市人民检察院</div>
              </div>
              <div style="display: flex;margin-top: 16px;">
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHlsZT0iZmlsbDogcmdiYSgyMTUsIDIxNSwgMjE1LCAxKTtib3JkZXItY29sb3I6IHJnYmEoMCwwLDAsMCk7Ym9yZGVyLXdpZHRoOiBicHg7Ym9yZGVyLXN0eWxlOiB1bmRlZmluZWQiIGZpbHRlcj0ibm9uZSI+PHBhdGggZD0iTTE2IDFIOEM2LjM0IDEgNSAyLjM0IDUgNHYxNmMwIDEuNjYgMS4zNCAzIDMgM2g4YzEuNjYgMCAzLTEuMzQgMy0zVjRjMC0xLjY2LTEuMzQtMy0zLTN6bS0yIDIwaC00di0xaDR2MXptMy4yNS0zSDYuNzVWNGgxMC41djE0eiIvPjwvc3ZnPg=="
                  width="19px">
                <div> &nbsp;技术运营：贵州中汇科技发展有限公司</div>
              </div>
              <div style="display: flex;margin-top: 16px;">
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiBzdHlsZT0iZmlsbDogcmdiYSgyMTUsIDIxNSwgMjE1LCAxKTtib3JkZXItY29sb3I6IHJnYmEoMCwwLDAsMCk7Ym9yZGVyLXdpZHRoOiBicHg7Ym9yZGVyLXN0eWxlOiB1bmRlZmluZWQiIGZpbHRlcj0ibm9uZSI+PHBhdGggZD0iTTIuOTkxIDkuMTM5bDEyLjMyOS03LjM5MmMwLjE5Ni0wLjExOSAwLjQzMy0wLjE5IDAuNjg2LTAuMTlzMC40OSAwLjA3MSAwLjY5MiAwLjE5M2wtMC4wMDYtMC4wMDMgMTIuMzE3IDcuMzkzYzAuMTk1IDAuMTE5IDAuMzI0IDAuMzMgMC4zMjQgMC41NzIgMCAwIDAgMCAwIDB2MCAxNi45NTVjMCAwLjczNi0wLjU5NyAxLjMzMy0xLjMzMyAxLjMzM3YwaC0yNGMtMC43MzYgMC0xLjMzMy0wLjU5Ny0xLjMzMy0xLjMzM3YwLTE2Ljk1NmMwLTAgMC0wIDAtMCAwLTAuMjQyIDAuMTI5LTAuNDUzIDAuMzIxLTAuNTdsMC4wMDMtMC4wMDJ6TTUuMzMzIDEwLjg0NHYxNC40ODloMjEuMzMzdi0xNC40OTFsLTEwLjY2MS02LjQtMTAuNjcyIDYuNHpNMTYuMDgwIDE4LjI2NGw3LjA2MS01Ljk1MSAxLjcxNyAyLjA0MC04Ljc2IDcuMzgzLTguOTQ3LTcuMzczIDEuNjk2LTIuMDU5IDcuMjMyIDUuOTZ6Ij48L3BhdGg+PC9zdmc+"
                  width="19px">
                <div>&nbsp;联系电话：17385852785</div>
              </div>
              <div style="display: flex;align-items: flex-start;margin-top: 16px;text-align: left;">
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHlsZT0iZmlsbDogcmdiYSgyMTUsIDIxNSwgMjE1LCAxKTtib3JkZXItY29sb3I6IHJnYmEoMCwwLDAsMCk7Ym9yZGVyLXdpZHRoOiBicHg7Ym9yZGVyLXN0eWxlOiB1bmRlZmluZWQiIGZpbHRlcj0ibm9uZSI+PHBhdGggZD0iTTE1LjM5MiAyOS4zMTJjLTAuNDk3LTAuNjQtMS4wNzUtMS40MzItMS42MzQtMi4yMzlsLTAuMTI2LTAuMTkzYy0xLjk4NC0yLjc4NC0zLjc3Ni01LjY2NC01LjI0OC04LjczNi0wLjcwNC0xLjUwNC0xLjM3Ni0zLjA3Mi0xLjY5Ni00LjcwNC0wLjYwOC0zLjEwNCAwLjEyOC01Ljg4OCAyLjIwOC04LjIyNCAyLjAxNi0yLjMwNCA0LjYwOC0zLjMyOCA3LjY0OC0zLjIgNC4yMjQgMC4xNiA3LjkwNCAzLjM2IDguNzY4IDcuNTIgMC4xMzIgMC41OTYgMC4yMDcgMS4yOCAwLjIwNyAxLjk4MyAwIDEuMDg4LTAuMTgxIDIuMTM0LTAuNTE1IDMuMTA5bDAuMDIwLTAuMDY3Yy0xLjA1NiAzLjMyOC0yLjY4OCA2LjMzNi00LjU3NiA5LjIxNi0xLjM0NCAyLjA4MC0yLjgxNiA0LjA2NC00LjIyNCA2LjA4MC0wLjAzMiAwLjAzMi0wLjA2NCAwLjA5Ni0wLjEyOCAwLjEyOC0wLjA2NCAwLjA5Ni0wLjM4NC0wLjIyNC0wLjcwNC0wLjY3MnpNMjAuNjcyIDExLjY0OGMwLjA5Ni0yLjQtMS44ODgtNC42NC00LjI4OC00Ljc2OC0yLjc1Mi0wLjE2LTQuODk2IDEuNzYtNS4wMjQgNC41NDQtMC4xMjggMi40IDEuOTIgNC42NCA0LjM1MiA0LjczNiAyLjY4OCAwLjEyOCA0LjgtMS43OTIgNC45Ni00LjUxMnoiPjwvcGF0aD48L3N2Zz4="
                  width="19px">
                <div> &nbsp;邮箱：38554808@qq.com</div>
              </div>
            </a-col>
            <a-col :span="24" :lg="4">
              <a-row :gutter="24">
                <a-col :span="24">
                  <div
                    style="box-sizing: border-box;padding: 10px;box-shadow: 0px 0px 12px -4px rgba(14, 155, 210, 20);border-radius: 8px;display: inline-block;margin-bottom: 10px;">
                    <img :src="$staticImg('/icon_sw_app_download_qrcode.png')" width="100%" />
                  </div>
                  <div style="font-size: 14px;color: #0E9BD2;font-weight: 400;">扫码下载APP</div>
                </a-col>
                <!--								<a-col :span="12">
                                  <div style="box-sizing: border-box;padding: 10px;box-shadow: 0px 0px 12px -4px rgba(14, 155, 210, 20);border-radius: 8px;display: inline-block;margin-bottom: 10px;">
                                    <img src="images/qrcode.png" width="100%" />
                                  </div>
                                  <div style="font-size: 14px;color: #0E9BD2;font-weight: 400;">扫码下载APP</div>
                                </a-col>-->
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DefaultFooter',
  props: {

  },
  computed: {
    ...mapState(['fullWidth'])
  }
}
</script>

<style></style>
