import parames_ from './parames.vue';
import md5 from 'js-md5';
import axios from 'axios';
import qs from 'qs'
import store from "../store/index.js"
import 'element-ui/lib/theme-chalk/index.css';
import router from '../router/index';
import {Message} from 'element-ui';

//非需要token验证的接口请求
function http(datas) {
    let requestData = {
        body: JSON.stringify(datas.data)
    }
    return new Promise((resolve) => {
        try {
            axios({
                method: datas.method ? datas.method : 'post',
                url: parames_.serverSrc + datas.url,
                data: qs.stringify(requestData),
                headers: {
                    'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
                .then((res) => {
                    console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
                    console.log("params:" + JSON.stringify(requestData))
                    console.log("response:" + JSON.stringify(res))
                    resolve(res.data)
                })
                .catch((error) => {
                    console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
                    console.log("params:" + JSON.stringify(requestData))
                    console.log("response:出错")
                    Message({
                        message: '服务器繁忙',
                        type: 'error'
                    })
                });
        } catch (e) {
            console.log(e)
            console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
            console.log("params:" + JSON.stringify(requestData))
            console.log("response:出错")
            Message({
                message: '服务器繁忙',
                type: 'error'
            })
        }
    })
}

/**
 * 需要APPKEY的接口请求
 * @param {Object} datas
 */
function httpAppKey(datas) {
    var timeStamp = new Date().getTime();
    var sign = (md5(parames_.AppKey + timeStamp + JSON.stringify(datas.data))).toUpperCase();
    var requestData = {
        body: JSON.stringify(datas.data),
        timeStamp: timeStamp,
        sign: sign,
    }
    return new Promise((resolve,rejected) => {
        try {
            axios({
                method: datas.method ? datas.method : 'post',
                url: parames_.serverSrc + datas.url,
                data: qs.stringify(requestData),
                headers: {
                    'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
                .then((res) => {
                    console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
                    console.log("params:" + JSON.stringify(requestData))
                    console.log("response:" + JSON.stringify(res))
                    resolve(res.data)
                })
                .catch((error) => {
                    console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
                    console.log("params:" + JSON.stringify(requestData))
                    console.log("response:出错")
                    Message({
                        message: '服务器繁忙',
                        type: 'error'
                    })
                    rejected('请求出错')
                });
        } catch (e) {
            console.log("\n\n接口信息:" + parames_.serverSrc + datas.url)
            console.log("params:" + JSON.stringify(requestData))
            console.log("response:出错")
            Message({
                message: '服务器繁忙',
                type: 'error'
            })
            rejected('请求出错')
        }
    })
}

//需要token验证的接口请求
function httpToken(datas) {
    console.log("开始请求")
    console.log(datas.data)
    var timeStamp = new Date().getTime();
    var requestData = va_signkey(datas.data)
    console.log("签名")
    console.log(JSON.stringify(requestData))
    let that = this
    return new Promise((resolve) => {
        try {
            axios({
                method: datas.method ? datas.method : 'post',
                url: parames_.serverSrc + datas.url,
                data: qs.stringify(requestData),
                headers: {
                    'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            }).then((res) => {
                console.log("接口信息token:" + parames_.serverSrc + datas.url)
                console.log("params:" + JSON.stringify(requestData))
                console.log("response:" + JSON.stringify(res))
                let code = res.data.code
                if (code == 401 || code == 1011 || (code >= 2000 && code <= 2006) || code == 8002  || code == 8011) {
                    Message({
                        message: store.getters.isLogin ? res.data.msg : "请先登录",
                        type: 'error'
                    })
                    that.loginOutStore()
                    router.push({
                        name:"Login"
                    })
                }else{
                    resolve(res.data)
                }
            }).catch((error) => {
                Message({
                    message: error,
                    type: 'error'
                })
            });
        } catch (e) {
            Message({
                message: '服务器繁忙',
                type: 'error'
            })
        }
    })

}

//验签数据处理:需要token 验证验签
function va_signkey(data) {

    let timeStamp = new Date().getTime()
    let USER_KEY = {
        uid: store.getters.uid,
        token: store.getters.token
    }
    console.log(store.getters.uid)
    console.log(store.getters.token)
    console.log("000009090")
    if (!data.uid) {
        data.uid = store.getters.uid
    } else {
        data.uid = ""
    }
    let sign = md5(USER_KEY.token + timeStamp + JSON.stringify(data)).toUpperCase();
    let dataobj = {
        token: store.getters.token ? store.getters.token : "",
        uid: USER_KEY.uid ? USER_KEY.uid : "",
        sign: sign,
        timeStamp: timeStamp,
        body: JSON.stringify(data)
    }
    console.log('签名对象')
    console.log(dataobj)
    return dataobj;
}

let isRefreshing = true;

function checkStatus(params) {
    // 刷新token的函数,这需要添加一个开关，防止重复请求
    if (isRefreshing) {
        Message({
            message: '登录信息已过期，请重新登录！',
            type: 'error'
        })
        store.commit('clearStore')
        setTimeout(() => { //设置延迟执行
            location.href = "/";
        }, 1000);
    }
    isRefreshing = false;
    // 将token刷新成功后的回调请求缓存
    setTimeout(function () {
        isRefreshing = true
    }, 3000);
}

//对象排序的函数【A-Z】
function objKeySort(arys) {
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newkey = Object.keys(arys).sort();
    //console.log('newkey='+newkey);
    var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj[newkey[i]] = arys[newkey[i]];
        //向新创建的对象中按照排好的顺序依次增加键值对

    }
    return newObj; //返回排好序的新对象
}

/**
 * 生成任意长随计数方法如下：
 */
function randomNum(n) {
    var t = '';
    for (var i = 0; i < n; i++) {
        t += Math.floor(Math.random() * 10);
    }
    return t;
}

function loginStore(obj) {
    console.log("准备存储loginStore")
    store.commit('setUserInfo', obj)
}

function loginOutStore() {
    console.log("清除存储loginStore")
    store.commit('clearUserInfor', {})
}
function apiDownLoad(url,data){
    return new Promise((resolve) =>{
        axios({
            method:'get',
            url:url,
            params:data,
            responseType:'blob'
        }).then(res =>{
            resolve(res.data);
        }).catch(err =>{
            resolve('error');
        })
    })
}
//将需要的方法暴露出去，使其能被其他模板访问
export default {
    http, //不需要token验证的请求方法
    httpToken, //需要token验证的请求方法
    httpAppKey,
    loginStore,
    loginOutStore,
    apiDownLoad,
}