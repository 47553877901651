<template>
  <div class="home">
    <div class="banner-img">
      <img src="images/home-banner.png" width="100%" height="auto" />
    </div>
    <div>
      <a-row class="introduce" type="flex" justify="center">
        <a-col :span="20" :lg="14">
          <div class="introduce-title">守未联盟——未成年人司法保护云平台</div>
          <div class="introduce-text">
            家庭保护、学校保护、社会保护、网络保护、政府保护
          </div>
          <div class="introduce-section">
            <a-row type="flex" justify="space-between" align="middle">
              <a-col v-for="(item, index) in section" :key="index" :span="8" :lg="2">
                <img :src="item.path" width="66px" />
                <div class="default-font" style="padding: 13px 0">{{ item.name }}</div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <a-row class="report" type="flex" justify="center">
        <a-col :span="20" :lg="14">
          <div style="display: flex; align-items: center; justify-content: center">
            <img src="images/title-icon.png" width="56px" />
            <div class="title-font">强制报告</div>
          </div>
          <div class="introduce-text">
            一键报告、多部门联动、报告分流、实时处理、及时跟踪，满足不同场景强制报告需求
          </div>
          <div class="default-font" style="text-align: left; margin-top: 11px">
            《关于建立侵害未成年人案件强制报告制度的意见（试行）》规定，国家机关、居民委员会、村民委员会、密切接触未成年人的单位及其工作人员，在工作中发现未成年人身心健康受到侵害、疑似受到侵害或者面临其他危险情形的，应当立即向公安、民政、教育等有关部门报告
          </div>
          <a-row :gutter="100" style="margin-top: 40px">
            <a-col :span="24" :lg="6">
              <img src="images/report/1.png" width="123px" />
              <div class="report-title" style="margin-top: 9px">多端口报告</div>
              <div class="report-text" style="margin-top: 15px">
                APP+网站+微信小程序公众号矩阵
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <img src="images/report/2.png" width="123px" />
              <div class="report-title" style="margin-top: 9px">专人及时处置</div>
              <div class="report-text" style="margin-top: 15px">
                系统自动分发属地公安派出所专职人员实时快速接收处置
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <img src="images/report/3.png" width="123px" />
              <div class="report-title" style="margin-top: 9px">多部门协同</div>
              <div class="report-text" style="margin-top: 15px">
                根据事件情况，民政、司法等部门各司其职，协同帮扶
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <img src="images/report/4.png" width="123px" />
              <div class="report-title" style="margin-top: 9px">检察实时法律监督</div>
              <div class="report-text" style="margin-top: 15px">
                检察、监察部门全程、实时监督事件处理情况，保证未成年问题满意解决
              </div>
            </a-col>
            <a-col :span="24" style="margin-top: 34px; margin-bottom: 45px">
              <a-button ghost shape="round" type="primary" size="large" style="padding: 10px 70px; height: auto">
                <div style="font-size: 14px; font-weight: 400; color: #0e9bd2" @click="gotoReportAction">
                  立即报告 >>
                </div>
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row class="device" type="flex" justify="center">
        <a-col :span="20" :lg="14">
          <div style="display: flex; align-items: center; justify-content: center">
            <img src="images/title-icon.png" width="56px" />
            <div class="title-font">法治教育云课堂</div>
          </div>
          <div class="introduce-text">未成年人法治教育资源库</div>
          <a-row :gutter="20" style="margin-top: 40px">
            <a-col :span="24" :lg="6">
              <div class="item-center-wzc">
                <el-image src="images/classroom/1.png" fit="fill"></el-image>
                <div class="report-text" style="margin-top: 15px">
                  汇聚大量专业人士直播讲授
                </div>
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <div class="item-center-wzc">
                <el-image src="images/classroom/2.png"></el-image>
                <div class="report-text" style="margin-top: 15px">
                  海量收集优质法治教育课程
                </div>
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <div class="item-center-wzc">
                <el-image src="images/classroom/3.png"></el-image>
                <div class="report-text" style="margin-top: 15px">
                  直播可回放，课程可下载
                </div>
              </div>
            </a-col>
            <a-col :span="24" :lg="6">
              <div class="item-center-wzc">
                <el-image src="images/classroom/4.png"></el-image>
                <div class="report-text" style="margin-top: 15px">
                  支持用户自主上传及分享
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24" style="margin-top: 34px; margin-bottom: 45px">
              <div>
                <a-button ghost shape="round" type="primary" size="large" style="padding: 10px 70px; height: auto">
                  <div style="font-size: 14px; font-weight: 400; color: #0e9bd2" @click="gotoCloudCourseAction()">
                    查看更多 >>
                  </div>
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- <a-row class="device" type="flex" justify="center">
        <a-col :span="20" :lg="14">
          <div style="display: flex; align-items: center; justify-content: center">
            <img src="images/title-icon.png" width="56px" />
            <div class="title-font">未成年家庭自主申报——未保码</div>
          </div>
          <div class="introduce-text">未成年人一码通行</div>
          <a-row
            type="flex"
            align="middle"
            justify="center"
            style="margin-top: 34px; position: relative"
          >
            <a-col :span="24" :lg="16">
              <div class="report-title" style="font-size: 20px; text-align: left">
                应用场景
              </div>
              <div class="report-text" style="margin-top: 15px">
                （1）应用于防疫<br />
                作为健康码使用，减轻家长截图上报负担，提前预警精准防疫；<br />
                （2）应用于学校<br />
                替换传统学生证，到校、离校扫码自动通知监护人，助力孩子安全；<br />
                （3）应用于门票<br />
                作为未成年人进入公共场所的优待政策门票凭证使用；<br />
                （4）应用于公共交通<br />
                作为未成年人乘坐公共交通的免票或折扣票凭证，扫码进站及上车时，自动通知监护人，防止走失；<br />
                （5）应用于酒店<br />
                替代身份证进行未成年入住扫码登记，有危险时自动通知监护人并自动报警；<br />
              </div>
              <div style="text-align: left; margin-top: 26px">
                <a-button
                  ghost
                  shape="round"
                  type="primary"
                  size="large"
                  style="padding: 5px 14px; height: auto"
                  @click="showMiniProPopup = true"
                >
                  <div style="font-size: 14px; font-weight: 400; color: #0e9bd2">
                    立即申请 >>
                  </div>
                </a-button>
              </div>
            </a-col>
            <a-col :span="24" :lg="8">
              <div style="display: flex; flex-direction: column">
                <div class="report-text" style="margin-top: 15px; text-align: left">
                  未成年家庭自主申报申请未保码，接入校园、医院、酒店、公交出行、景区基地、健康码等未成年应用场景一码通行，辍学、失学、拐卖、离家出走、暴力伤害等安全、健康预警，筑牢未成年人保护防线
                </div>
                <div>
                  <img :src="$staticImg('/icon_web_card.png')" style="width: 400px" />
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row> -->
      <a-row class="report" type="flex" justify="center" style="margin-bottom: 50px">
        <a-col :span="20" :lg="14">
          <div style="display: flex; align-items: center; justify-content: center">
            <img src="images/title-icon.png" width="56px" />
            <div class="title-font">守未服务</div>
          </div>
          <div class="introduce-text">未成年服务一网通办</div>
          <a-row :gutter="24" style="margin-top: 30px" type="flex" align="middle">
            <a-col :span="24" :lg="10">
              <!-- <div class="report-title" style="font-size: 20px;text-align: left;">应用场景</div> -->
              <a-row type="flex" align="middle" style="margin-top: 10px">
                <a-col :span="1">
                  <div class="item-dot"></div>
                </a-col>
                <a-col :span="23">
                  <div class="report-text">
                    全面覆盖18岁以下未成年用户群体从出生、落户、入学、就医等政务服务，数据多跑路，群众少跑腿
                  </div>
                </a-col>
              </a-row>
              <div style="text-align: left; margin-top: 26px">
                <a-button ghost shape="round" type="primary" size="large" style="padding: 5px 14px; height: auto"
                  @click="gotoService()">
                  <div style="font-size: 14px; font-weight: 400; color: #0e9bd2">
                    查看更多 >>
                  </div>
                </a-button>
              </div>
            </a-col>
            <a-col :span="24" :lg="14">
              <img src="images/serve-img.png" width="90%" />
            </a-col>
          </a-row>
          <a-row style="
                background-color: #f8fafd;
                padding: 20px 0 10px 0;
                border-radius: 99px;
                margin-top: 37px;
              ">
            <a-col :span="8" :lg="4" style="
                  margin-bottom: 12px;
                  font-size: 14px;
                  color: #595959;
                  font-weight: 400;
                " v-for="(se, sindex) in serveList" :key="sindex">{{ se }}</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>

    <el-dialog :visible.sync="showMiniProPopup" :modal="true" @close="showMiniProPopup = false"
      @opened="showMiniProPopup = true" :modal-append-to-body="true" :append-to-body="true"
      :width="fullWidth > 992 ? '30%' : '90%'" center>
      <div slot="title" class="mimipro--title">请扫描下方微信小程序二维码申领</div>

      <div class="mimipro">
        <div class="mimipro--tip"></div>
        <img class="mimipro--qrcode" :src="$staticImg('/sw_mpwx_qrcode.jpeg')" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
const section = [
  {
    path: "images/sections/fayuan.png",
    name: "法院",
  },
  {
    path: "images/sections/jianchayuan.png",
    name: "检察",
  },
  {
    path: "images/sections/jiancha.png",
    name: "监察",
  },
  {
    path: "images/sections/jiaoyu.png",
    name: "教育",
  },
  {
    path: "images/sections/gongan.png",
    name: "公安",
  },
  {
    path: "images/sections/minzheng.png",
    name: "民政",
  },
  {
    path: "images/sections/sifa.png",
    name: "司法",
  },
  {
    path: "images/sections/weijian.png",
    name: "卫健",
  },
  {
    path: "images/sections/tuanwei.png",
    name: "团委",
  },
  {
    path: "images/sections/fulian.png",
    name: "妇联",
  },
];
const serveList = [
  "经济救助",
  "转学安置",
  "法律援助",
  "司法救助",
  "心理危机干预",
  "社会融入辅导",
  "家庭教育指导",
  "成立家长委员会",
  "医疗救治",
  "欺凌防控处置机制",
  "未成年矫正服务",
  "公益服务",
  "卫生保健服务",
  "未保热线",
  "残疾未成年教育",
  "早期识别和诊断治疗",
  "儿童之家",
  "残疾未成年服务",
];
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      section: section,
      serveList: serveList,
      showMiniProPopup: false,
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  mounted() {
    console.log("home------mounted");
  },
  activated() {
    console.log("home------activated");
  },
  methods: {
    gotoService() {
      this.$router.push({ name: "Serve", path: "/serve" });
    },
    gotoReportAction() {
      this.$router.push({ name: "Report", path: "/report" });
    },
    gotoCloudCourseAction() {
      this.$router.push({ name: "Classroom", path: "/classroom" });
    },
  },
};
</script>

<style lang="scss">
.mimipro {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--title {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
    color: #000;
  }

  &--tips {}
}

.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.item-center-wzc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
