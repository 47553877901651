import "@babel/polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import DefaultLayout from './layouts/Layout.vue'
import element from './ElementUI.vue'
import http from './config/api_ajax.js';
import moment from 'moment'
import params from './config/parames.vue';
import 'moment/locale/zh-cn'
import '@/static/public.css'

import { isEmpty } from './utils/index.js'
import vueMiniPlayer from './player/index'
import './player/lib/vue-mini-player.css'
import dayjs from "dayjs"
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.use(element)
moment.locale('zh-cn')
Vue.component("layout-default", DefaultLayout);
Vue.use(Antd);
Vue.component(VueQrcode.name, VueQrcode);
Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.prototype.$login = http.loginStore
Vue.prototype.$HTTP = http;
Vue.prototype.$bus = new Vue();
Vue.prototype.dayjs = dayjs
Vue.prototype.$staticImg = function (url) {
    if (!isEmpty(url) && url.indexOf("http") == 0) return url
    return params.prodStaticSourceHost + url
}
Vue.prototype.isEmpty = function (str) {
    return isEmpty(str)
}
Vue.prototype.htmlFormat = function(htmlContent){
	console.log('准备解析',htmlContent)
	if(!htmlContent || htmlContent.length == 0){
		return ''
	}
	let str = htmlContent.replace(/<img[^>]*>/gi, function(match, capture) {
		return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig,
			'style="width:100%;height:auto;"') // 替换style
	})
	str = str.replace(/<video[^>]*>/gi, function(match, capture) {
		return match.replace(/width="auto"/ig,
			'width="100%"') // 替换style
	})
	str = str.replace(/mp4/gi,'mp4#t=0.1')
	str = str.replace(/video\/mp4#t=0.1/gi,'video/mp4')
	return str
}

Vue.use(vueMiniPlayer)

new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this//安装全局事件总线
    }
}).$mount('#app')
