<script>
import "element-ui/lib/theme-chalk/index.css";
import {
  Message,
  MessageBox,
  Tabs,
  TabPane,
  DatePicker,
  Select,
  Option,
  Upload,
  Drawer,
  Cascader,
  Dialog,
  Button,
  Loading,
  Checkbox,
  Form,
  FormItem,
  Input,
  RadioGroup,
  RadioButton,
  Pagination,
  Image,
  Empty,
  PageHeader,
  Card,
  Divider,
  Progress,
  Carousel,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Radio,
  Tag,
  Popover,
  Popconfirm,
  CheckboxGroup,
  Switch,
  Avatar,
  Alert,
} from "element-ui";

const element = {
  install: function (Vue) {
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(DatePicker);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Upload);
    Vue.use(Drawer);
    Vue.use(Cascader);
    Vue.use(Dialog);
    Vue.use(Button);
    Vue.use(Loading);
    Vue.use(Checkbox);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Input);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Pagination);
    Vue.use(Image);
    Vue.use(Empty);
    Vue.use(PageHeader);
    Vue.use(Card);
    Vue.use(Divider);
    Vue.use(Progress);
    Vue.use(Carousel);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Radio);
    Vue.use(Tag);
    Vue.use(Popover);
    Vue.use(Switch);
    Vue.use(Popconfirm);
    Vue.use(CheckboxGroup);
    Vue.use(Avatar);
    Vue.use(Alert);
    Vue.component(MessageBox.name, MessageBox);

    Vue.prototype.$elementMessage = Message;
    Vue.prototype.$elementAlert = MessageBox.alert;
    Vue.prototype.$elementConfirm = MessageBox.confirm;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$loading = Loading;
  },
};
export default element;
</script>
