/**
 * 判断字符串是否为空 
 */
export const isEmpty = (s) => {
	if (!s || typeof s == "undefined" || s == null || trim(s, 1) == "") {
		return true;
	} else {
		return false;
	}
}

export const trim = (str, type) => {
	type = type || 1
	switch (type) {
		case 1:
			return str.replace(/\s+/g, "");
		case 2:
			return str.replace(/(^\s*)|(\s*$)/g, "");
		case 3:
			return str.replace(/(^\s*)/g, "");
		case 4:
			return str.replace(/(\s*$)/g, "");
		default:
			return str;
	}
}
// 1.邮箱
export const isEmail = (s) => {
	return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
// 2.手机号码
export const isMobile = (s) => {
	return /^1[0-9]{10}$/.test(s)
}
// 3.电话号码
export const isPhone = (s) => {
	return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}
// 4.是否url地址
export const isURL = (s) => {
	return /^http[s]?:\/\/.*/.test(s)
}
// 5.是否字符串
export const isString = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'String'
}
// 6.是否数字
export const isNumber = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Number'
}
// 7.是否boolean
export const isBoolean = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean'
}
// 8.是否函数
export const isFunction = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Function'
}
// 9.是否为null
export const isNull = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Null'
}
// 10.是否undefined
export const isUndefined = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Undefined'
}
// 11.是否对象
export const isObj = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Object'
}
// 12.是否数组

export const isArray = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Array'
}
// 13.是否时间

export const isDate = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Date'
}
// 14.是否正则

export const isRegExp = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'RegExp'
}
// 15.是否错误对象

export const isError = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Error'
}
// 16.是否Symbol函数

export const isSymbol = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Symbol'
}
// 17.是否Promise对象

export const isPromise = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Promise'
}
// 18.是否Set对象

export const isSet = (o) => {
	return Object.prototype.toString.call(o).slice(8, -1) === 'Set'
}


// 38.严格的身份证校验

export const isCardID = (sId) => {
	if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
		console.log('你输入的身份证长度或格式错误')
		return false
	}
	//身份证城市
	var aCity = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外"
	};
	if (!aCity[parseInt(sId.substr(0, 2))]) {
		console.log('你的身份证地区非法')
		return false
	}

	// 出生日期验证
	var sBirthday = (sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2))).replace(
		/-/g, "/"),
		d = new Date(sBirthday)
	if (sBirthday != (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
		console.log('身份证上的出生日期非法')
		return false
	}

	// 身份证号码校验
	var sum = 0,
		weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
		codes = "10X98765432"
	for (var i = 0; i < sId.length - 1; i++) {
		sum += sId[i] * weights[i];
	}
	var last = codes[sum % 11]; //计算出来的最后一位身份证号码
	if (sId[sId.length - 1] != last) {
		console.log('你输入的身份证号非法')
		return false
	}

	return true
}

/*1、日期格式转换
 *用法说明：   
 * 	直接调用：输入时间（毫秒数）和  格式
 *		formatDate(new Date().getTime());
 *		formatDate(new Date().getTime(),'YY年MM月DD日');
 *		formatDate(new Date().getTime(),'今天是YY/MM/DD hh:mm:ss');
 */
export const FormatDate = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
	const config = {
		YYYY: date.getFullYear(),
		MM: date.getMonth(),
		DD: date.getDate(),
		HH: date.getHours(),
		mm: date.getMinutes(),
		ss: date.getSeconds(),
	}
	for (const key in config) {
		format = format.replace(key, config[key])
	}
	return format
}

/* 对Date的扩展，将 Date 转化为指定格式的String       
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符       
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)       
 * eg:       
 * "yyyy-MM-dd hh:mm:ss.S"   ==> 2006-07-02 08:09:04.423       
 * "yyyy-MM-dd E HH:mm:ss"   ==> 2009-03-10 二 20:09:04       
 * "yyyy-MM-dd EE hh:mm:ss"  ==> 2009-03-10 周二 08:09:04       
 * "yyyy-MM-dd EEE hh:mm:ss" ==> 2009-03-10 星期二 08:09:04       
 * "yyyy-M-d h:m:s.S"        ==> 2006-7-2 8:9:4.18       
 */
export const formatDate = (date, fmt) => {
	let o = {
		'M+': date.getMonth() + 1,//月份
		'd+': date.getDate(), //日   
		'H+': date.getHours(),//小时       
		"h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时           
		'm+': date.getMinutes(),//分 
		"s+": date.getSeconds(), //秒                      
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度  
		"S": date.getMilliseconds()   //毫秒      
	};
	var week = {
		"0": "日",
		"1": "一",
		"2": "二",
		"3": "三",
		"4": "四",
		"5": "五",
		"6": "六"
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	if (/(E+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "星期" : "周") : "") + week[date.getDay() + ""]);
	}
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
		}
	}
	return fmt;
};
//
function padLeftZero(str) {
	return ('00' + str).substr(str.length);
}

function commonDateTimeIntval(timeString) {
	if (timeString) {
		return new Date(timeString.replace(/-/g, "/")).getTime()
	}
}
//列表倒计时
export const countDownAction = function countDownV2(item, createTime, date, isToSecond = false) {
	//时间差值
	let countDownTime
	if (createTime) {
		//现在时间
		let nowTime = Date.now()
		//结束时间
		let endTime = commonDateTimeIntval(createTime) + date * 24 * 3600 * 1000
		countDownTime = endTime - nowTime

		let leftTime = Math.abs(countDownTime)

		let leftsecond = parseInt(leftTime / 1000); //计算剩余的秒数

		let day = Math.floor(leftsecond / (60 * 60 * 24));

		let hour = Math.floor((leftsecond - day * 24 * 60 * 60) / 3600);
		let minute = Math.floor((leftsecond - day * 24 * 60 * 60 - hour * 3600) / 60);
		let second = Math.floor(leftTime / 1000 % 60, 10);
		let value = `${day}天${hour}小时${minute}分钟`;
		if (isToSecond) {
			value = value + `${second}秒`
		}
		if (countDownTime > 0) {
			item.countDownDate = "剩余" + value
		} else if (countDownTime <= 0) {
			item.countDownDate = "已超时" + value
		}
	}
}

// 获取Windows版本号
export const getWindowVersion = () => {
	var ua = navigator.userAgent.toLowerCase();
	var version = null;
	var reg = /nt [\d._]+/gi;
	var v_info = ua.match(reg);
	version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号9.3.2或者9.0
	// version = parseInt(version.split('.')[0]); // 得到版本号第一位
	console.log('version :>> ', version);
	return version;
}
// 获取Mac版本号
export const getMacVersion = () => {
	var ua = navigator.userAgent.toLowerCase();
	var version = null;
	var reg = /x [\d._]+/gi;
	var v_info = ua.match(reg);
	version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号9.3.2或者9.0
	// version = parseInt(version.split('.')[0]); // 得到版本号第一位
	return version;
}
//获得浏览器名称
export const getBrowser = () => {
	var UserAgent = navigator.userAgent.toLowerCase();
	var browserInfo = {};
	var browserArray = {
		IE: window.ActiveXObject || "ActiveXObject" in window,
		// IE       
		Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1,
		// Chrome浏览器       
		Firefox: UserAgent.indexOf('firefox') > -1,
		// 火狐浏览器       
		Opera: UserAgent.indexOf('opera') > -1,
		// Opera浏览器        
		Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1,
		// safari浏览器        
		Edge: UserAgent.indexOf('edge') > -1,
		// Edge浏览器        
		QQBrowser: /qqbrowser/.test(UserAgent),
		// qq浏览器        
		WeixinBrowser: /MicroMessenger/i.test(UserAgent)
		// 微信浏览器  
	};

	console.log(browserArray)
	for (var i in browserArray) {
		if (browserArray[i]) {
			var versions = '';
			if (i == 'IE') {
				versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2];
			} else if (i == 'Chrome') {
				for (var mt in navigator.mimeTypes) {

					//检测是否是360浏览器(测试只有pc端的360才起作用)      
					if (navigator.mimeTypes[mt]['type'] == 'application/360softmgrplugin') {
						i = '360';
					}
				}
				versions = UserAgent.match(/chrome\/([\d.]+)/)[1];
			} else if (i == 'Firefox') {
				versions = UserAgent.match(/firefox\/([\d.]+)/)[1];
			} else if (i == 'Opera') {
				versions = UserAgent.match(/opera\/([\d.]+)/)[1];
			} else if (i == 'Safari') {
				versions = UserAgent.match(/version\/([\d.]+)/)[1];
			} else if (i == 'Edge') {
				versions = UserAgent.match(/edge\/([\d.]+)/)[1];
			} else if (i == 'QQBrowser') {
				versions = UserAgent.match(/qqbrowser\/([\d.]+)/)[1];
			}
			browserInfo.type = i; browserInfo.versions = parseInt(versions);
		}
	}
	console.log('browserInfo :>> ', browserInfo);
	return browserInfo;
}
