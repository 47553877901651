<template>
  <div class="_vol"
       @click.stop="handleClick">
    <!-- 非静音 -->
    <svg v-if="!isMuted"
         viewBox="0 0 40 40"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none"
         stroke-width="1"
         fill="none"
         fill-rule="evenodd">
        <g transform="translate(-40.000000, -281.000000)">
          <g transform="translate(40.000000, 281.000000)">
            <g transform="translate(0.000000, 4.000000)">
              <path d="M10.314666,7.29159253 L10.314666,23.8296452 L4.05278755,23.8296452 C2.32789564,23.8296452 0.927090038,22.5856319 0.927090038,21.1011808 L0.927090038,10.0200629 C0.927090038,8.50947627 2.31222825,7.29159253 4.05278755,7.29159253 L10.314666,7.29159253 L10.314666,7.29159253 Z"
                    fill="#FFFFFF"
                    fill-rule="nonzero"></path>
              <path d="M18.0191834,0.674274796 C19.237061,-0.240440841 20.2249539,0.277026428 20.2249539,1.78238658 L20.2249539,29.3335821 C20.2249539,30.8598483 19.2475171,31.361636 18.0191834,30.4416938 L9.19610116,23.8296087 L9.19610116,7.29155607 L18.0191834,0.674244409 L18.0191834,0.674274796 Z"
                    fill="#FFFFFF"
                    fill-rule="nonzero"></path>
              <path d="M29.5357411,25.6333387 C32.0371112,23.0341574 33.5750798,19.5011972 33.5750798,15.6090251 C33.5750798,11.7410714 32.0561911,8.2278731 29.582322,5.63333866"
                    stroke="#FFFFFF"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <!-- 静音 -->
    <svg v-else
         viewBox="0 0 40 40"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none"
         stroke-width="1"
         fill="none"
         fill-rule="evenodd">
        <g transform="translate(-40.000000, -354.000000)"
           fill="#FFFFFF"
           fill-rule="nonzero">
          <g transform="translate(40.000000, 354.000000)">
            <g transform="translate(0.000000, 5.000000)">
              <path d="M10.314666,7.29159253 L10.314666,23.8296452 L4.05278755,23.8296452 C2.32789564,23.8296452 0.927090038,22.5856319 0.927090038,21.1011808 L0.927090038,10.0200629 C0.927090038,8.50947627 2.31222825,7.29159253 4.05278755,7.29159253 L10.314666,7.29159253 L10.314666,7.29159253 Z"></path>
              <path d="M18.0191834,0.674274796 C19.237061,-0.240440841 20.2249539,0.277026428 20.2249539,1.78238658 L20.2249539,29.3335821 C20.2249539,30.8598483 19.2475171,31.361636 18.0191834,30.4416938 L9.19610116,23.8296087 L9.19610116,7.29155607 L18.0191834,0.674244409 L18.0191834,0.674274796 Z"></path>
              <path d="M23.9256893,11.143836 C23.1573282,10.3754749 23.1573282,9.12100861 23.9256893,8.35265052 L23.9570512,8.32128901 C24.7254123,7.55292788 25.9798786,7.55292788 26.7482367,8.32128901 L38.4461438,20.0191961 C39.2145049,20.7875573 39.2145049,22.0420236 38.4461438,22.8103817 L38.4147823,22.8417435 C37.6411947,23.6153311 36.3919549,23.6153311 35.6235968,22.8417435 L23.9256893,11.143836 Z"></path>
              <path d="M23.9517611,19.9930508 L35.6496683,8.29514369 C36.4180294,7.52678255 37.6724957,7.52678255 38.4408538,8.29514369 L38.4722156,8.32650519 C39.2405767,9.09486633 39.2405767,10.3493326 38.4722156,11.1176907 L26.7743085,22.8208244 C26.0059473,23.5891855 24.751481,23.5891855 23.9831229,22.8208244 L23.9517614,22.7894625 C23.1781738,22.0158749 23.1781738,20.7666351 23.9517614,19.9930505 L23.9517611,19.9930508 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isMuted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    $video() {
      return this.$parent.$parent.$video;
    }
  },
  methods: {
    handleClick() {
      const isMuted = !this.isMuted;
      this.$emit('update:isMuted', isMuted);
      this.$video && (this.$video.muted = isMuted);
    }
  }
};
</script>

<style lang="scss" scoped>
._vol {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>

