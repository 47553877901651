import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		fullWidth: document.documentElement.clientWidth,
		pageName: ['Home'],
		userInfo: window.localStorage.getItem("SW_USER_INFO") || {},
		num1: 0,
		specialId: '',
		userFlagId: '',// 用户考试提交成绩时所需id
		departName: '',
		sumProgress: 0
	},
	mutations: {
		handleResize(state) {
			state.fullWidth = document.documentElement.clientWidth
		},
		setPageName(state, name) {
			state.pageName = [name]
		},
		setUserInfo(state, info) {
			console.log("正在保存用户信息0000")
			state.userInfo = info
			let str = JSON.stringify(info)
			console.log("正在保存用户信息")
			console.log(str)
			// console.log(JSON.parse(decodeURIComponent(atob(str))))
			localStorage.setItem('SW_USER_INFO', str)
			Vue.prototype.$bus.$emit("loginEvent", true)
		},
		clearUserInfor(state, info) {
			console.log("正在清除用户信息")
			localStorage.removeItem('SW_USER_INFO')
			state.userInfo = {}
			Vue.prototype.$bus.$emit("loginEvent", false)

		},
		SET_NUM1(state, index) {
			state.num1 = index;
		},
		SET_SPECIALID(state, id) {
			state.specialId = id;
		},
		SET_USERFLAGID(state, data) {
			state.userFlagId = data;
		},
		SET_DEPARTNAME(state, data) {
			state.departName = data;
		},
		SET_SUMPROGRESS(state, data) {
			state.sumProgress = data;
		}
	},
	getters: {
		token(state) {
			return state.userInfo.bijieAppToken
		},
		uid(state) {
			return state.userInfo.id
		},
		ryToken(state) {
			return state.userInfo.ryToken
		},
		userName(state) {
			return state.userInfo.realname
		},
		isLogin(state) {
			return state.userInfo.id && state.userInfo.id != undefined && state.userInfo.id != null && state.userInfo.id != ''
		},
	},
	actions: {
		set_num1({ commit }, data) {
			commit('SET_NUM1', data)
		},
		set_specialId({ commit }, data) {
			commit('SET_SPECIALID', data);
		},
		set_userFlagId({ commit }, data) {
			commit('SET_USERFLAGID', data);
		},
		set_departName({ commit }, data) {
			commit('SET_DEPARTNAME', data)
		},
		set_sumProgress({ commit }, data) {
			commit('SET_SUMPROGRESS', data)
		}
	},
	modules: {}
})
